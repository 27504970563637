@import '../../base/mixins';

.review-articles {
  &__title {
    margin-bottom: 40px;

    @include screen-from(md) {
      margin-bottom: 52px;
    }

    @include screen-from(lg) {
      margin-bottom: 68px;
    }
  }

  &__items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__item {
    width: 50%;
    opacity: 0.65;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }

    @include screen-from(md) {
      width: initial;
      margin-right: 13%;
    }

    @include screen-from(lg) {
      margin-right: 0;
    }

    &:nth-of-type(-n+6) {
      margin-bottom: 44px;

      @include screen-from(lg) {
        margin-bottom: 0;
      }
    }

    &:nth-of-type(n+5) {
      @include screen-from(md) {
        margin-bottom: 0;
      }
    }

    &:nth-of-type(4n) {
      @include screen-from(md) {
        margin-right: 0;
      }
    }
  }

  &__icon {
    display: block;
  }
}
