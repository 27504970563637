@import '../../base/variables';
@import '../../base/mixins';

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  margin-top: 44px;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: calc(100% - 40px);
    padding-bottom: 280px;
    margin-bottom: 32px;

    @include screen-from(md) {
      padding-bottom: 0;
      border: none;
      margin-bottom: 94px;
    }

    @include screen-from(lg) {
      margin-bottom: 128px;
    }
  }

  &__logo {
    width: 96px;
    height: 96px;

    @include screen-from(md) {
      width: 120px;
      height: 120px;
    }

    @include screen-from(lg) {
      width: 160px;
      height: 160px;
    }
  }

  &__title.h1_hero {
    color: $white;
    margin: 24px 0 32px;

    @include screen-to(sm) {
      hyphens: auto;
    }

    @include screen-from(md) {
      margin: 28px 0 46px;
      width: 100%;
      max-width: 420px;
      font-size: $font-size-h1-hero-mobile;
    }

    @include screen-from(lg) {
      margin: 40px 0 48px;
      max-width: 680px;
      font-size: $font-size-h1-hero-tablet;
    }
  }

  &__buttons-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 320px;

    @include screen-from(md) {
      max-width: 300px;
    }

    @include screen-from(lg) {
      max-width: none;
    }
  }

  &__cta-button {
    width: 100%;
    margin-bottom: 20px;

    @include screen-from(lg) {
      width: auto;
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  &__social-button-wrapper {
    display: flex;
    width: 100%;

    @include screen-from(lg) {
      width: fit-content;
    }
  }

  &__social-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    &:first-of-type {
      margin-right: 12px;
    }

    @include screen-from(lg) {
      width: 62px;
    }

    &.btn_light {
      padding: 9px 8px;
    }
  }

  &__description {
    margin-top: 20px;
    max-width: 280px;
    font-size: $font-size-text-sm;
    color: $white;

    @include screen-from(md) {
      margin-top: 28px;
    }

    @include screen-from(lg) {
      max-width: 320px;
      font-size: $font-size-text-lg;
    }
  }

  &__badge-pics {
    width: 98px;
    height: 98px;
    top: calc(100% - 217px);
    left: calc(50% + 58px);
    z-index: 2;

    @include screen-from(md) {
      top: 60px;
      left: calc(50% - 360px);
    }

    @include screen-from(lg) {
      top: 46px;
      left: calc(50% - 604px);
    }
  }

  &__badge-macpaw {
    width: 33px;
    height: 33px;
    top: calc(100% - 147px);
    left: calc(50% + 52px);
    z-index: 4;

    @include screen-from(md) {
      top: 135px;
      left: calc(50% - 361px);
    }

    @include screen-from(lg) {
      top: 177px;
      left: calc(50% - 605px);
    }
  }

  &__badge-pdf {
    width: 81px;
    height: 81px;
    top: calc(100% - 148px);
    left: calc(50% + 71px);
    z-index: 3;

    @include screen-from(md) {
      top: 131px;
      left: calc(50% - 302px);
    }

    @include screen-from(lg) {
      top: 170px;
      left: calc(50% - 500px);
    }
  }

  &__badge-teamwork {
    width: 128px;
    height: 65px;
    top: calc(100% - 200px);
    left: calc(50% - 62px);
    z-index: 1;

    @include screen-from(md) {
      top: 227px;
      left: calc(50% - 360px);
    }

    @include screen-from(lg) {
      top: 339px;
      left: calc(50% - 608px);
    }
  }

  &__badge-secure {
    width: 100px;
    height: 100px;
    top: calc(100% - 220px);
    left: calc(50% - 159px);
    z-index: 2;

    @include screen-from(md) {
      top: 273px;
      left: calc(50% - 360px);
    }

    @include screen-from(lg) {
      top: 418px;
      left: calc(50% - 610px);
    }
  }

  &__badge-wifi {
    width: 114px;
    height: 72px;
    top: calc(100% - 156px);
    right: calc(50% - 240px);
    transform: rotate(35deg);
    z-index: 3;

    @include screen-from(md) {
      transform: none;
      top: 70px;
      right: calc(50% - 376px);
    }

    @include screen-from(lg) {
      top: 64px;
      right: calc(50% - 650px);
    }
  }

  &__badge-plan {
    width: 96px;
    height: 96px;
    top: calc(100% - 166px);
    right: calc(50% + 129px);
    z-index: 5;

    @include screen-from(md) {
      top: 105px;
      right: calc(50% - 306px);
    }

    @include screen-from(lg) {
      top: 124px;
      right: calc(50% - 526px);
    }
  }

  &__badge-converter {
    display: none;

    @include screen-from(md) {
      display: block;
      width: 88px;
      height: 88px;
      top: 167px;
      right: calc(50% - 384px);
      z-index: 6;
    }

    @include screen-from(lg) {
      top: 235px;
      right: calc(50% - 664px);
    }
  }

  &__badge-manage {
    width: 130px;
    height: 72px;
    top: calc(100% - 139px);
    right: calc(50% + 12px);
    z-index: 3;

    @include screen-from(md) {
      top: 226px;
      right: calc(50% - 352px);
    }

    @include screen-from(lg) {
      top: 336px;
      right: calc(50% - 606px);
    }
  }

  &__badge-code {
    width: 86px;
    height: 86px;
    top: calc(100% - 146px);
    right: calc(50% - 63px);
    z-index: 5;

    @include screen-from(md) {
      top: 286px;
      right: calc(50% - 338px);
    }

    @include screen-from(lg) {
      top: 440px;
      right: calc(50% - 587px);
    }
  }

  &__badge {
    position: absolute;

    @include screen-from(lg) {
      width: auto;
      height: auto;
    }
  }
}
