@import '../../base/variables';
@import '../../base/mixins';
@import '../jumbotron';

.video-preview {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding: 0;

  &_dirty-purple {
    background-color: $dirty-purple;
  }

  &_dark-grey-blue {
    background-color: $dark-grey-blue;
  }

  &_very-light-brown {
    background-color: $very-light-brown;
  }

  &_steel {
    background-color: $steel;
  }

  &_small {
    padding: 80px 20px 20px;
    justify-content: center;

    @include screen-from(md) {
      padding: 92px 76px 52px;
    }

    @include screen-from(xl) {
      padding: 32px;
    }
  }

  &__content {
    display: flex;
  }

  &__content_small {
    width: 100%;

    @include screen-from(xl) {
      flex-wrap: nowrap;
    }
  }

  &__image-container {
    display: inherit;
  }

  &__image-container_small {
    padding: 0 6px;
    position: relative;
    height: 180px;

    @include screen-from(sm) {
      height: 292px;
    }

    @include screen-from(md) {
      padding: 0;
      max-width: 592px;
      width: 592px;
      height: 392px;
    }

    @include screen-from(xl) {
      min-width: 592px;
    }
  }

  &__image {
    height: 178px;
    margin-top: 22px;
    margin-right: 22px;

    @include screen-from(sm) {
      height: 380px;
      margin-right: 50px;
    }

    @include screen-from(md) {
      margin-top: 279px;
    }

    @include screen-from(xl) {
      height: 520px;
      margin-top: 48px;
    }

    @media (min-width: 1440px) {
      height: 672px;
      margin-right: 0;
    }
  }

  &__image_small {
    position: absolute;
    bottom: 0;
    left: 36px;
    right: 0;
    margin: auto;
    height: 178px;

    @include screen-from(sm) {
      left: 56px;
      height: 288px;
    }

    @include screen-from(md) {
      left: 72px;
      height: 388px;
    }
  }

  &__image-background {
    width: 100%;
    opacity: 0.2;
    border-radius: 20px;

    &_dark-grey-blue {
      background: #b6d1da;
    }

    &_steel {
      background: $white;
    }
  }

  &__preview-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include screen-from(md) {
      top: 130px;
      left: 26px;
      transform: translate(0);
    }

    @media (min-width: 1440px) {
      top: 210px;
    }
  }

  &__preview-container_small {
    position: relative;
    top: unset;
    left: unset;
    transform: none;

    @include screen-from(xl) {
      padding: 68px 20px 0 0;
      flex-shrink: 1;
    }
  }

  &__preview-description {
    max-width: 556px;
    color: $white;
    line-height: 1.54;
    font-weight: $font-weight-medium;
    margin-bottom: 20px;
  }

  &__preview-name {
    color: $white;
    line-height: normal;
    font-size: 16px;
    margin-bottom: 128px;
  }

  &__preview-name_small {
    margin-bottom: 24px;
  }

  &__play-btn {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    box-shadow: none;

    @include screen-from(md) {
      top: unset;
      left: 26px;
      bottom: 72px;
      right: unset;
    }
  }

  &__play-btn_small {
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
