@import '../../base/variables';

.testimonial-card {
  padding: 7px 7px 20px;
  border-radius: 15px;
  background: $smoke-gray;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__quote {
    font-size: 26px;
    line-height: 1.42;
    font-weight: $font-weight-medium;
    padding: 39px 22px;
    border-radius: 8px;
    color: $white;
    flex: 1;
  }

  &__avatar {
    position: relative;
    border-radius: 50%;
    margin-top: -25px;
    margin-left: 22px;
    width: 50px;
    height: 50px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.45);
  }

  &__description {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 0 22px;
    margin-top: 14px;
  }

  &__social-icon {
    align-self: flex-end;
  }

  &__author-name {
    color: $text-color-base;
    font-size: 18px;
    font-weight: $font-weight-bold;
  }

  &__author-nick {
    color: #9f9f9f;
    font-size: 14px;
    font-weight: $font-weight-bold;
  }
}
