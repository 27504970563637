@import '../../base/variables';
@import '../../base/mixins';

.explore-setapp {
  color: $white;

  &__info {
    border-bottom: 1px solid $light-silver;
    padding-bottom: 48px;

    @include screen-from(xl) {
      padding-bottom: 88px;
    }
  }

  &__highlights-wrapper {
    position: relative;
    margin-top: 48px;
    margin-bottom: 60px;

    @include screen-from(md) {
      margin-top: 60px;
      margin-bottom: 100px;
    }

    @include screen-from(xl) {
      height: 530px;
      margin-top: 156px;
      margin-bottom: 216px;
    }
  }

  &__image {
    margin: 0 auto 24px;
    display: block;
    width: 100%;
    height: auto;
    max-width: 920px;

    @include screen-from(md) {
      margin-bottom: 40px;
    }

    @include screen-from(xl) {
      display: inline-block;
      position: relative;
      bottom: 37px;
      left: 130px;
      padding: 48px 61px 26px 27px;
      margin-bottom: 0;
    }
  }

  &__highlights {
    display: grid;
    grid-template-columns: 1fr;
    gap: 28px;

    @include screen-from(md) {
      grid-template-columns: 1fr 1fr;
    }

    @include screen-from(xl) {
      position: static;
    }
  }

  &__highlight {
    display: flex;
    align-items: flex-start;

    @include screen-from(xl) {
      position: relative;
    }

    &-left-top {
      top: -628px;
      left: 12px;
    }

    &-right-top {
      top: -628px;
      left: -20px;

      @include screen-from(xl) {
        flex-direction: row-reverse;

        .explore-setapp__highlight-icon {
          margin-right: 0;
          margin-left: 16px;
        }
      }
    }

    &-left-bottom {
      bottom: 122px;
      left: 12px;
    }

    &-right-bottom {
      bottom: 122px;
      left: -38px;

      @include screen-from(xl) {
        flex-direction: row-reverse;

        .explore-setapp__highlight-icon {
          margin-right: 0;
          margin-left: 16px;
        }
      }
    }
  }

  &__highlight-title {
    font-size: $font-size-text-lg;

    @include screen-from(xl) {
      white-space: nowrap;
      font-size: $font-size-h5;
    }
  }

  &__highlight-icon {
    margin-top: 4px;
    margin-right: 16px;
  }

  &__highlight-4 {
    bottom: -44px;
    right: 32px;

    @include screen-from(xl) {
      flex-direction: row-reverse;

      .explore-setapp__highlight-icon {
        margin-right: 0;
        margin-left: 16px;
      }
    }
  }

  &__arrow {
    position: relative;
    display: none;

    @include screen-from(xl) {
      display: inline-block;
    }

    &-left-top {
      bottom: 749px;
      left: 109px;
    }

    &-right-top {
      bottom: 704px;
      left: 373px;
    }

    &-left-bottom {
      bottom: 377px;
      right: 600px;
    }

    &-right-bottom {
      bottom: 388px;
      right: 32px;
    }
  }

  &__benefits {
    border-top: 1px solid $light-silver;
    padding-top: 40px;
    padding-bottom: 48px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 48px 64px;

    @include screen-from(md) {
      padding-top: 60px;
      padding-bottom: 60px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include screen-from(xl) {
      padding-top: 80px;
      padding-bottom: 80px;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__benefit-title {
    margin-top: 20px;
    margin-bottom: 8px;
  }

  &__benefit-description {
    font-size: $font-size-text-md;
  }
}
