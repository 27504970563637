@import '../../base/variables';
@import '../../base/mixins';

.apps {
  color: $white;

  &__content {
    border-top: 1px solid $light-silver;
    padding-top: 48px;
    padding-bottom: 80px;

    @include screen-from(md) {
      padding-top: 80px;
      padding-bottom: 100px;
    }

    @include screen-from(lg) {
      padding-top: 100px;
      padding-bottom: 160px;
    }
  }

  &__info {
    margin-bottom: 40px;
    padding: 0;

    @include screen-from(md) {
      margin-bottom: 60px;
      padding: 0 26px;
    }

    @include screen-from(lg) {
      margin-bottom: 88px;
      padding: 0;
    }
  }

  &__title {
    margin-bottom: 0;
    color: $white;
  }

  &__app-wrapper {
    margin-bottom: 20px;
  }

  &__app-link {
    text-decoration: none;
  }

  &__app {
    overflow: hidden;
    border-radius: 20px;
    height: 520px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 30px;

    @include screen-from(md) {
      row-gap: 22px;
    }

    @include screen-from(lg) {
      row-gap: 28px;
      height: 640px;
    }

    &_cmm {
      padding-top: 40px;
      padding-bottom: 28px;
      background: #ffe0e4;

      @include screen-from(md) {
        padding-top: 44px;
        padding-bottom: 0;
        row-gap: 22px;
      }

      @include screen-from(lg) {
        padding-top: 60px;
        padding-bottom: 0;
        row-gap: 28px;
      }
    }

    &_code-runner {
      padding-bottom: 40px;
      flex-direction: column-reverse;
      row-gap: 0;
      background: $pale;

      @include screen-from(lg) {
        padding-bottom: 60px;
      }
    }

    &_session {
      padding-top: 40px;
      background: #373737;

      @include screen-from(lg) {
        padding-top: 60px;
      }
    }

    &_n-track-studio {
      padding-bottom: 40px;
      flex-direction: column-reverse;
      row-gap: 0;
      background: $charcoal-grey-4;

      @include screen-from(lg) {
        padding-bottom: 60px;
      }
    }

    &_mockuuups-studio {
      padding-top: 40px;
      background: #f3f3f3;

      @include screen-from(lg) {
        padding-top: 60px;
      }
    }

    &_mindnode {
      padding-bottom: 40px;
      flex-direction: column-reverse;
      background: #9191e1;

      @include screen-from(lg) {
        padding-bottom: 60px;
      }
    }

    &_permute {
      padding-top: 40px;
      row-gap: 0;
      background: #f0ad84;

      @include screen-from(lg) {
        padding-top: 60px;
      }
    }

    &_bartender {
      padding-bottom: 40px;
      flex-direction: column-reverse;
      row-gap: 0;
      background: $dusk;

      @include screen-from(lg) {
        padding-bottom: 60px;
        height: 560px;
      }
    }
  }

  &__app-info {
    padding-right: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px 32px;
    color: $white;

    @include screen-from(md) {
      padding-right: 26px;
      padding-left: 26px;
    }

    @include screen-from(lg) {
      padding-right: 60px;
      padding-left: 60px;
    }

    &_wide {
      @include screen-from(sm) {
        flex-direction: row;
        align-items: flex-start;
      }
    }

    &_black {
      color: $dark-grey;
    }
  }

  &__app-title {
    margin: 0;
    color: $white;
    font-size: $font-size-h5;
    font-weight: $font-weight-bold;

    @include screen-from(lg) {
      font-size: $font-size-h4;
    }

    &_black {
      color: $dark-grey;
    }
  }

  &__app-image-wrapper {
    padding-left: 12px;
    justify-content: right;

    @include screen-from(md) {
      padding-left: 0;
      justify-content: center;
    }
  }

  &__app-image {
    width: 100%;
    height: auto;

    &_session {
      @include screen-from(lg) {
        padding: 0 30px;
      }

      @include screen-from(xl) {
        padding: 0 20px;
      }
    }

    &_cmm {
      position: relative;
      left: 19%;
      min-width: 390px;

      @include screen-from(sm) {
        width: 100%;
        position: static;
      }

      @include screen-from(md) {
        max-width: 728px;
      }

      @include screen-from(lg) {
        max-width: 1148px;
      }
    }

    &_mindnote {
      padding: 48px 28px 0 16px;
    }

    &_bartender {
      max-width: 372px;

      @include screen-from(sm) {
        max-width: 414px;
      }

      @include screen-from(md) {
        max-width: 728px;
      }

      @include screen-from(lg) {
        max-width: 1154px;
      }
    }
  }

  &__button {
    padding-top: 20px;
    text-align: center;

    @include screen-from(md) {
      padding-top: 28px;
    }

    @include screen-from(lg) {
      padding-top: 40px;
    }
  }
}
