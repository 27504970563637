@import '../../base/variables';
@import '../../base/mixins';

.video-modal {
  height: 100%;
  padding: 46px 10px 10px;
  background: $light-gray;

  @include screen-from(lg) {
    padding: 50px 125px;
  }

  &__video {
    background: #000;
    height: 100%;
    width: 100%;
    padding: 14px;
    border-radius: 20px;

    @include screen-from(lg) {
      padding: 18px 27px;
    }
  }
}
