@import '../../base/variables';
@import '../../base/mixins';
@import '../../components/testimonials/testimonials';
@import '../../components/testimonials/testimonial-card';
@import '../../components/jumbotron';
@import 'header';
@import 'apps';
@import 'video-testimonials';
@import 'reviews';
@import 'signup';
@import 'explore-setapp';
@import 'home-badges';

.home {
  background: $dark-grey;

  &__video-testimonials {
    background-image: linear-gradient(to bottom, $dark-grey 0%, $dark-grey 50%, #fefefe 50%, #fefefe 100%);
  }

  &__feedback {
    padding-top: 80px;
    background: #fefefe;

    @include screen-from(md) {
      padding-top: 100px;
    }

    @include screen-from(lg) {
      padding-top: 120px;
    }
  }

  &__review-articles {
    padding: 80px 0;
    background: #fefefe;

    @include screen-from(md) {
      padding: 100px 0 80px;
    }

    @include screen-from(lg) {
      padding: 120px 0;
    }
  }

  &__home-badges {
    padding-top: 80px;
    background: #fefefe;

    @include screen-from(md) {
      padding-top: 100px;
    }

    @include screen-from(lg) {
      padding-top: 120px;
    }
  }

  &__signup {
    padding-bottom: 20px;
    background-image: linear-gradient(to bottom, #fefefe 0, #fefefe 50%, $charcoal-grey-2 50%, $charcoal-grey-2 100%);

    &-store {
      background-image: linear-gradient(to bottom, #fefefe 0, #fefefe 50%, $charcoal-grey-6 50%, $charcoal-grey-6 100%);
    }
  }
}
