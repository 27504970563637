.home-badges {
  &__content-wrapper {
    padding: 0 20px;

    @include screen-from(xl) {
      padding: 0 130px;
      max-width: $layout-navigation-width-max;
      margin: 0 auto;
    }
  }

  &__title {
    margin-bottom: 20px;

    @include screen-from(md) {
      margin-left: 26px;
      margin-bottom: 24px;
    }

    @include screen-from(xl) {
      font-size: $font-size-h2;
      margin-left: 0;
    }
  }
}
