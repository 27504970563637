@import '../../base/variables';
@import '../../base/mixins';

.signup {
  &__logo {
    display: block;
    margin: 46px 0 22px;

    @include screen-from(md) {
      margin-top: 104px;
    }
  }

  &__price {
    margin-bottom: 0;
  }

  &__buttons {
    margin: 44px 0 16px;

    @include screen-from(md) {
      margin-bottom: 72px;
    }

    @include screen-from(sm) {
      margin-top: 60px;
    }
  }

  &__button {
    width: 100%;

    & + & {
      margin-top: 15px;
    }

    @include screen-from(sm) {
      width: auto;

      & + & {
        margin-top: 0;
        margin-left: 20px;
      }
    }
  }
}
