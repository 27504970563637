@import '../../base/mixins';
@import '../../base/variables';

.carousel {
  padding: 25px 0;

  &__navigation-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;

    &_with-tabs {
      justify-content: space-between;

      .carousel__arrows {
        display: none;

        @include screen-from(lg) {
          display: flex;
        }
      }
    }
  }

  &__tab-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__tab {
    color: $dark-grey;
    background: $smoke-gray;
    font-size: 16px;
    line-height: 24px;
    padding: 6px 16px;

    &:active {
      background: $dark-grey;
      color: $white;
    }

    &:hover {
      background: $light-silver;
    }

    &_active {
      background: $dark-grey;
      color: $white;

      &:hover {
        background: #3e3e46;
      }
    }
  }

  &__arrows {
    display: none;

    @include screen-from(sm) {
      text-align: right;
      display: flex;
      font-size: 0;
    }
  }

  &__arrow {
    height: 24px;
    width: 14px;
    padding: 0;
    border: none;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1;
    }

    &-next {
      background: url(image-url('/components/carousel/arrow.svg')) no-repeat center;
    }

    &-prev {
      background: url(image-url('/components/carousel/arrow.svg')) no-repeat center;
      transform: rotate(180deg);
      margin-right: 46px;
    }
  }

  &__slides {
    margin: 0 -10px;
    white-space: nowrap;

    /* stylelint-disable-next-line selector-max-type */
    > div {
      display: inline-flex;
      white-space: initial;
    }
  }

  &__slide {
    padding: 5px 10px;
    height: 100%;
  }

  &__pagination {
    margin-top: 24px;
    text-align: center;
    font-size: 0;
  }

  &__pagination-item {
    border-radius: 50%;
    background-color: #d5d4d4;
    padding: 0;
    border: none;
    cursor: pointer;
    width: 12px;
    height: 12px;

    & + & {
      margin-left: 25px;
    }

    &_active {
      background-color: #1d1d22;
    }
  }
}
