@import '../../base/variables';
@import '../../base/mixins';
@import '../../components/video-preview/video-preview';

.video-testimonials {
  .carousel {
    position: relative;

    &__arrows {
      display: none;
      position: absolute;
      top: 90px;
      right: 51px;
      z-index: 100;

      @include screen-from(md) {
        display: inline-block;
      }

      @include screen-from(lg) {
        right: 120px;
      }
    }

    &__arrow {
      opacity: 0.49;

      &:hover {
        opacity: 0.8;
      }

      &:active {
        opacity: 1;
      }

      &-next {
        background: url(image-url('/pages/home/video-testimonials/arrow.svg')) no-repeat center;
      }

      &-prev {
        background: url(image-url('/pages/home/video-testimonials/arrow.svg')) no-repeat center;
      }
    }
  }
}
