@import '../../base/variables';
@import '../../base/mixins';

.testimonial__moto {
  letter-spacing: 1.3px;
  font-size: 36px;
  font-weight: $font-weight-bold;
  line-height: 1.33;
  margin-bottom: 30px;

  @include screen-to(sm) {
    hyphens: auto;
  }

  @include screen-from(xl) {
    margin: 0;
  }

  &_white {
    color: $white;
  }
}

.testimonial__social-links {
  padding-top: 3px;

  @include screen-from(md) {
    text-align: right;
  }
}
